<template>
  <div>
    <el-input disabled v-model="showValue">
      <template slot="append">
        <el-upload
            ref="uploader"
            :action="action"
            :headers="{ytoken:$store.state.sys.account.token}"
            name="file"
            :show-file-list="false"
            accept=".crt"
            :data="{target:mode}"
            :on-success="success"
            :on-error="error"
            :limit="1"
        >
          <el-button type="primary">选择证书文件</el-button>
        </el-upload>
      </template>
    </el-input>
  </div>
</template>

<script>


export default {
  name: "y_upload_ali_cert",
  model:{
    prop:"modelval",
    event:'change'
  },
  props:{
    mode:{
      type:String,
      default: "app_cert_public_key.crt"
    },
    modelval: {
      type:Boolean,
      default:false
    }
  },
  watch:{
    modelval(){
      this.value = this.modelval;
    },
    value(e){
      this.$emit('change',e);
    }
  },
  data() {
    return {
      action:"",
      value: this.modelval,
    }
  },
  mounted() {
    this.action = this.axios.defaults.baseURL +  this.$api.sys.attach.aliPayPemAction;
  },
  computed:{
    showValue(){
      return this.modelval ? '已上传' : "未上传";
    }
  },
  methods:{
    success(){
      this.$refs.uploader.clearFiles();
      this.$message.success("上传成功");
      this.$emit("load")
    },
    error(e){
      console.log(e)
    },
    handleClose(done){
      done();
    },
  }
}
</script>
<style scoped>

</style>
<style>
.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8rem;
  height: 8rem;
  background: #f5f7fa;
  color: #909399;
  font-size: 30px;
}
</style>